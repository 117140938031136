import React, { useState, useEffect } from "react";
import { ClavisViewer } from "@artsdatabanken/clavis-viewer-web";

function Clavis({uuid, scientificNameFilter}) {
  const [clavis, setClavis] = useState(null);

  useEffect(() => {
    fetch(`https://clavis.test.artsdatabanken.no/key/${uuid}`)
      .then((response) => response.json())
      .then((data) => {
        setClavis(data);
      })
      .catch((error) => console.log(error));
  }, []);

  if (!clavis) {
    return <div>Loading...</div>;
  }

  return (
    <ClavisViewer
      clavis={clavis}
      color="#dd8508"
      scientificNameFilter={scientificNameFilter}
    />
  );
}

export default Clavis;
