import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import UploadedImage from "./Image";
import { t } from "i18next";

const ImageSection = ({
  isInputStage,
  isResultStage,
  toggleMenu,
  croppedImages,
  editImage,
  goToInput,
}) => {
  return (
    <div className={"image-section"}>
      <div className="topBar">
        <>
          <MenuIcon
            className={
              "menu-icon" + (!isInputStage && !isResultStage ? " hidden" : "")
            }
            style={{ fontSize: "2em" }}
            onClick={toggleMenu}
          />

          <img
            src="Artsdatabanken_notext_mono_white.svg"
            alt="Artsdatabanken"
            className={
              "logo" + (!isInputStage && !isResultStage ? " hidden" : "")
            }
          />
        </>
      </div>

      <div
        className={
          "topContent" + (!isInputStage && !isResultStage ? " expanded" : "")
        }
      >
        {!croppedImages.length && isInputStage && (
          <div className="placeholder-container">
            <h1 className="placeholder-title">
              {t("placeholder.title")}
            </h1>
            <p className="placeholder-body">
            {t("placeholder.body")}
            </p>
          </div>
        )}

        <div className={"images scrollbarless"}>
          {croppedImages.map((img, index) => (
            <UploadedImage
              img={img}
              key={index}
              imgIndex={index}
              editImage={editImage}
            />
          ))}

          {!!croppedImages.length && (isInputStage || isResultStage) && (
            <div className="goToInput" onClick={goToInput}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
