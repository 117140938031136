export const runningOnMobile = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
};

export const Passthrough = ({ text }) => {
  return text;
};

const groupNames = {
  en: {
    Karplanter: "Vascular plants",
    Fugler: "Birds",
    Pattedyr: "Mammals",
    Lav: "Lichens",
    Sommerfugler: "Butterflies",
    Sopper: "Fungi",
    Nebbmunner: "True bugs",
    Moser: "Mosses",
    Bløtdyr: "Molluscs",
    Edderkoppdyr: "Arachnids",
    "Nettvinger, kakerlakker, saksedyr":
      "Net-winged insects, cockroaches, earwigs",
    Veps: "Wasps",
    Biller: "Beetles",
    Tovinger: "Diptera",
    Fisker: "Fish",
    "Amfibier, reptiler": "Amphibians, reptiles",
    "Døgnfluer, øyenstikkere, steinfluer, vårfluer":
      "Mayflies, dragonflies, stoneflies, caddisflies",
    "Armfotinger, pigghuder, kappedyr":
      "Arm-footed animals, echinoderms, tunicates",
  },
};

const vernacularNames = {
  en: {
    "Calopteryx virgo": "beautiful demoiselle",
    "Calopteryx splendens": "banded demoiselle",
    "Lestes sponsa": "emerald damselfly",
    "Lestes dryas": "scarce emerald damselfly",
    "Platycnemis pennipes": "white-legged damselfly",
    "Nehalennia speciosa": "pygmy damselfly",
    "Pyrrhosoma nymphula": "large red damselfly",
    "Erythromma najas": "red-eyed damselfly",
    "Ischnura elegans": "blue-tailed damselfly",
    "Ischnura pumilio": "scarce blue-tailed damselfly",
    "Enallagma cyathigerum": "common blue damselfly",
    "Coenagrion hastulatum": "spearhead bluet",
    "Coenagrion lunulatum": "crescent bluet",
    "Coenagrion armatum": "dark bluet",
    "Coenagrion puella": "azure damselfly",
    "Coenagrion pulchellum": "variable damselfly",
    "Coenagrion johanssoni": "Arctic bluet",
    "Aeshna cyanea": "southern hawker",
    "Aeshna viridis": "green hawker",
    "Aeshna isoceles": "green-eyed hawker",
    "Aeshna grandis": "brown hawker",
    "Aeshna juncea": "common hawker",
    "Aeshna subarctica": "subarctic darner",
    "Aeshna serrata": "Baltic hawker",
    "Aeshna mixta": "migrant hawker",
    "Aeshna caerulea": "azure hawker",
    "Brachytron pratense": "hairy dragonfly",
    "Anax imperator": "emperor dragonfly",
    "Hemianax ephippiger": "vagrant emperor",
    "Gomphus vulgatissimus": "common clubtail",
    "Onychogomphus forcipatus": "small pincertail",
    "Ophiogomphus cecilia": "green snaketail",
    "Cordulegaster boltonii": "golden-ringed dragonfly",
    "Epitheca bimaculata": "Eurasian baskettail",
    "Cordulia aenea": "downy emerald",
    "Somatochlora metallica": "brilliant emerald",
    "Somatochlora arctica": "northern emerald",
    "Somatochlora flavomaculata": "yellow-spotted emerald",
    "Somatochlora alpestris": "alpine emerald",
    "Somatochlora sahlbergi": "treeline emerald",
    "Libellula quadrimaculata": "four-spotted chaser",
    "Libellula depressa": "broad-bodied chaser",
    "Libellula fulva": "scarce chaser",
    "Orthetrum cancellatum": "black-tailed skimmer",
    "Orthetrum coerulescens": "keeled skimmer",
    "Leucorrhinia caudalis": "lilypad whiteface",
    "Leucorrhinia albifrons": "dark whiteface",
    "Leucorrhinia dubia": "white-faced darter",
    "Leucorrhinia rubicunda": "northern white-faced darter",
    "Leucorrhinia pectoralis": "large white-faced darter",
    "Sympetrum danae": "black darter",
    "Sympetrum flaveolum": "yellow-winged darter",
    "Sympetrum sanguineum": "ruddy darter",
    "Sympetrum striolatum": "common darter",
    "Sympetrum vulgatum": "vagrant darter",
    "Accipiter gentilis": "Northern goshawk",
    "Natrix natrix": "Grass snake",
    "Phylloscopus collybita": "Common chiffchaff",
    "Chloris chloris": "European greenfinch",
    "Phylloscopus collybita tristis/abietinus": "Siberian/common chiffchaff",
    "Motacilla flava": "Western yellow wagtail",
  },
};

export const getGroupName = (groupName, language) => {
  if (groupNames[language]) {
    return groupNames[language][groupName];
  } else {
    return groupName;
  }
};

export const getVernacularName = (result, language) => {
  if (result.vernacularName && language === "nb") {
    return (
      result.vernacularName.charAt(0).toUpperCase() +
      result.vernacularName.slice(1)
    );
  }
  if (vernacularNames[language] && vernacularNames[language][result.name]) {
    let vernacularName = vernacularNames[language][result.name];
    return (
      vernacularName.charAt(0).toUpperCase() +
      vernacularName.slice(1)
    );
  }

  return result.name;
};
