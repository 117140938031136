import React from "react";
import "../App.css";
import ReportButton from "./ReportButton";
import WarningIcon from "@mui/icons-material/Warning";
import TaxonImage from "./taxonImage";
import { useTranslation, Trans } from "react-i18next";
import { Passthrough, getGroupName, getVernacularName } from "../utils/utils";

function ExtendedResult({ result, croppedImages, preventClick }) {
  const percentage = Math.round(result.probability * 100);
  const n_pics = croppedImages.length;

  const { t, i18n } = useTranslation();
  let language = i18n.language;
  let vernacularName = getVernacularName(result, language);

  return (
    <div className="extendedResult scrollable scrollbarless">
      <div
        className="resultLabels"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "center",
            paddingBottom: "1.2rem",
          }}
        >
          <TaxonImage result={result} size={0.5 * 40 + "rem"} />
        </div>
        <div
          className={
            vernacularName.toLowerCase() === result.name.toLowerCase()
              ? "hyphenate vernacular italics"
              : "hyphenate vernacular"
          }
        >
          {vernacularName}
        </div>
        <div className="scientific hyphenate">
          {result.name}
        </div>
        <div className="group hyphenate">
          {getGroupName(result.groupName, language)}
        </div>
      </div>

      <div className="resultDescription">
        <Trans t={t} i18nKey="result.description" count={n_pics}>
          <Passthrough
            text={
              <span
                className={
                  vernacularName.toLowerCase() ===
                  result.name.toLowerCase()
                    ? "italics"
                    : ""
                }
              >
                {vernacularName}
              </span>
            }
          />
          {{ percentage: percentage }}
        </Trans>

        {result.groupName === "Sopper" && (
          <div className="danger">
            <WarningIcon /> {t("alerts.dontEatExtended")}
          </div>
        )}
      </div>
      <div className="resultActions">
        <a
          href={result.infoUrl}
          target={"_blank"}
          rel="noopener noreferrer"
          className="btn primary"
        >
          {t("buttons.aboutSpecies")}
        </a>
        <ReportButton
          reportResult={result}
          croppedImages={croppedImages}
          preventClick={preventClick}
        />
      </div>
    </div>
  );
}

export default ExtendedResult;
