import { useState, useCallback } from "react";
import generaLists from "../data/genera.json";
import i18next from "i18next";

const sumProbabilitiesByGenera = (results, generaLists) => {
  const lang = i18next.language;

  return Object.entries(generaLists).map(([key, value]) => ({
    id: value.id,
    taxon:
      value.name[lang] ||
      value.name.nb ||
      value.name.nn ||
      value.name.en ||
      key,
    relevantResults: results.filter((result) => {
      const genus = result.name.split(" ")[0];
      return value.genera.includes(genus);
    }),
    probability: results.reduce((sum, result) => {
      const genus = result.name.split(" ")[0];
      if (value.genera.includes(genus)) {
        return sum + result.probability;
      }
      return sum;
    }, 0),
  }));
};

const useCroppedImages = () => {
  const [croppedImages, setCroppedImages] = useState([]);

  const addCroppedImage = useCallback((img) => {
    setCroppedImages((prev) => [...prev, img]);
  }, []);

  const removeCroppedImage = useCallback((index) => {
    setCroppedImages((prev) =>
      prev.slice(0, index).concat(prev.slice(index + 1))
    );
  }, []);

  const clearCroppedImages = useCallback(() => {
    setCroppedImages([]);
  }, []);

  return {
    croppedImages,
    addCroppedImage,
    removeCroppedImage,
    clearCroppedImages,
  };
};

const useUncroppedImages = () => {
  const [uncroppedImages, setUncroppedImages] = useState([]);

  const addUncroppedImages = useCallback((images) => {
    setUncroppedImages((prev) => [...prev, ...Array.from(images)]);
  }, []);

  const clearUncroppedImages = useCallback(() => {
    setUncroppedImages([]);
  }, []);

  return { uncroppedImages, addUncroppedImages, clearUncroppedImages };
};

const useFullImages = () => {
  const [fullImages, setFullImages] = useState([]);

  const addFullImages = useCallback((images) => {
    setFullImages((prev) => [...prev, ...images]);
  }, []);

  const removeFullImage = useCallback((index) => {
    setFullImages((prev) => prev.slice(0, index).concat(prev.slice(index + 1)));
  }, []);

  const clearFullImages = useCallback(() => {
    setFullImages([]);
  }, []);

  return { fullImages, addFullImages, removeFullImage, clearFullImages };
};

const useImageHandling = () => {
  const {
    croppedImages,
    addCroppedImage,
    removeCroppedImage,
    clearCroppedImages,
  } = useCroppedImages();
  const { uncroppedImages, addUncroppedImages, clearUncroppedImages } =
    useUncroppedImages();
  const { fullImages, addFullImages, removeFullImage, clearFullImages } =
    useFullImages();

  const addImage = useCallback(
    async (images) => {
      addUncroppedImages(images);
    },
    [addUncroppedImages]
  );

  const imageCropped = useCallback(
    (img) => {
      if (img) {
        img.lastModifiedDate = new Date();
        img.name = new Date() + ".png";
        addCroppedImage(img);
        addFullImages(uncroppedImages);
      }
      clearUncroppedImages();
    },
    [uncroppedImages, addCroppedImage, addFullImages, clearUncroppedImages]
  );

  const editImage = useCallback(
    (index) => {
      addUncroppedImages([fullImages[index]]);
      removeFullImage(index);
      removeCroppedImage(index);
    },
    [fullImages, addUncroppedImages, removeFullImage, removeCroppedImage]
  );

  const resetImages = useCallback(() => {
    clearCroppedImages();
    clearUncroppedImages();
    clearCroppedImages();
    return true;
  }, [clearCroppedImages, clearUncroppedImages, clearCroppedImages]);

  return {
    croppedImages,
    uncroppedImages,
    fullImages,
    addImage,
    imageCropped,
    editImage,
    resetImages,
  };
};

export { sumProbabilitiesByGenera, generaLists };
export default useImageHandling;
