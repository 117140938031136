import React, { useState, useEffect, useCallback } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import IdResult from "./components/IdResult";
import ExtendedResult from "./components/ExtendedResult";
import UserFeedback from "./components/UserFeedback";
import ImageCropper from "./components/ImageCropper";
import Menu from "./components/Menu";
import About from "./components/About";
import ExtendedManual from "./components/ExtendedManual";
import Clavis from "./components/Clavis";
import ImageSection from "./components/ImageSection";
import useImageHandling, {
  sumProbabilitiesByGenera,
  generaLists,
} from "./hooks/useImageHandling";
import { Passthrough } from "./utils/utils";

function App() {
  const { t } = useTranslation();
  const {
    croppedImages,
    uncroppedImages,
    addImage,
    imageCropped,
    editImage,
    resetImages,
  } = useImageHandling();

  const [predictions, setPredictions] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputStage, setIsInputStage] = useState(true);
  const [isResultStage, setIsResultStage] = useState(false);
  const [chosenPrediction, setChosenPrediction] = useState(null);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isExtendedManualVisible, setIsExtendedManualVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [totalProbability, setTotalProbability] = useState(0);
  const [clavisUuid, setClavisUuid] = useState(null);
  const [clavisFilter, setClavisFilter] = useState([]);
  const [isClavisVisible, setIsClavisVisible] = useState(false);

  const handleReset = useCallback(() => {

    if (resetImages()) {
      setPredictions([]);
      setAlerts([]);
      setIsResultStage(false);
      setIsInputStage(true);
      setClavisUuid(null);
      setIsClavisVisible(false);
      setClavisFilter([]);
    }
  }, [resetImages]);

  const handleGeneraClick = useCallback((id, filter) => {
    if (filter.length > 1 && !clavisFilter.length) {
      setClavisFilter(filter);
    }

    if (!clavisUuid) {
      setClavisUuid(id);
    }

    setIsResultStage(false);
    setIsClavisVisible(true);
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setClavisUuid(null);
      setClavisFilter([]);
      setIsMenuVisible(false);
      setIsClavisVisible(false);
      setChosenPrediction(null);
      setIsAboutVisible(false);
      setIsExtendedManualVisible(false);
    };

    document.addEventListener("backbutton", handleBackButton, false);

    return () => {
      document.removeEventListener("backbutton", handleBackButton);
    };
  }, []);

  const toggleMenu = useCallback(() => {
    setIsMenuVisible((prev) => !prev);
  }, []);

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prev) => !prev);
  }, []);

  const closeModal = useCallback(() => {
    setChosenPrediction(null);
    setIsAboutVisible(false);
    setIsExtendedManualVisible(false);
  }, []);

  const goToInput = useCallback(() => {
    setIsResultStage(false);
    setPredictions([]);
    setAlerts([]);
    setIsInputStage(true);
    document.getElementById("uploaderImages").click();
  }, []);

  const uploadMore = useCallback(
    async (sender) => {
      await addImage(document.getElementById(sender).files);
      document.getElementById(sender).value = "";
    },
    [addImage]
  );

  const getId = useCallback(() => {
    setIsInputStage(false);
    setIsLoading(true);
    setClavisUuid(null);
    setClavisFilter([]);


    const formdata = new FormData();
    formdata.append("application", "Artsorakel Web");

    croppedImages.forEach((image) => {
      formdata.append("image", image);
    });

    axios
      .post("https://ai.artsdatabanken.no/", formdata)
      .then((res) => {
        let predictions = res.data.predictions[0].taxa.items.filter(
          (pred) => pred.probability > 0.02
        );

        if (predictions.length === 0) {
          predictions = res.data.predictions[0].taxa.items;
        }

        if (predictions.length > 5) {
          predictions = predictions.slice(0, 5);
        }

        setPredictions(predictions);
        setAlerts(res.data.alerts);
        setIsLoading(false);
        setIsResultStage(true);

        const probabilities = sumProbabilitiesByGenera(
          predictions,
          generaLists
        );
        setTotalProbability(probabilities);
      })
      .catch(() => {
        setIsResultStage(false);
        setIsInputStage(true);
        setIsLoading(false);
      });
  }, [croppedImages]);

  return (
    <React.Fragment>
      {!!uncroppedImages.length &&
        uncroppedImages.map((ucimg, index) => (
          <ImageCropper
            imgFile={ucimg}
            key={index}
            imageCropped={imageCropped}
            imgSize={500}
            darkMode={isDarkMode}
          />
        ))}

      <div
        id="AppContainer"
        className={"App" + (isDarkMode ? " darkmode" : " lightmode")}
      >
        <div
          id="modal"
          className={
            "modal " +
            (!!chosenPrediction | isAboutVisible | isExtendedManualVisible
              ? "visible"
              : "invisible")
          }
          onClick={closeModal}
        >
          <div
            className="content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="modalHeader">
              <CloseIcon onClick={closeModal} />
            </div>

            {!!chosenPrediction && (
              <ExtendedResult
                result={chosenPrediction}
                croppedImages={croppedImages}
              />
            )}

            {isAboutVisible && <About />}

            {isExtendedManualVisible && <ExtendedManual t={t} />}
          </div>
        </div>

        <div
          id="menu"
          className={"modal " + (isMenuVisible ? "visible" : "invisible")}
          onClick={toggleMenu}
        >
          <Menu
            resetImages={handleReset}
            toggleDarkMode={toggleDarkMode}
            toggleAbout={setIsAboutVisible}
            toggleManual={setIsExtendedManualVisible}
            darkMode={isDarkMode}
          />
        </div>

        {!isClavisVisible && (
          <ImageSection
            isInputStage={isInputStage}
            isResultStage={isResultStage}
            toggleMenu={toggleMenu}
            croppedImages={croppedImages}
            editImage={editImage}
            goToInput={goToInput}
          />
        )}

        <div
          className={
            "bottom-section scrollbarless " +
            (isInputStage || isResultStage || isClavisVisible ? "" : "hidden") +
            (isClavisVisible ? "nopadding" : "")
          }
        >
          {clavisUuid && (
            <div
              style={{
                display: isClavisVisible ? "initial" : "none",
              }}
            >
              <Clavis
                uuid={clavisUuid}
                scientificNameFilter={clavisFilter}
                onClose={() => {
                  setIsClavisVisible(false);
                  setIsResultStage(true);
                }}
              />

              <CloseIcon
                onClick={() => {
                  setIsClavisVisible(false);
                  setIsResultStage(true);
                }}
                tabIndex="0"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1,
                  cursor: "pointer",
                }}
              />
            </div>
          )}
          {isInputStage && !!croppedImages.length && (
            <div className="top-btn" onClick={getId} tabIndex="0">
              <div className="btn id primary">{t("buttons.identify")}</div>
            </div>
          )}

          {isResultStage && (
            <div className="top-btn">
              <div
                className="btn reset primary"
                onClick={handleReset}
                tabIndex="0"
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                  />
                </svg>
                {t("buttons.reset")}
              </div>
            </div>
          )}


          {isResultStage && !!predictions.length && (
            <div>
              {totalProbability
                .filter((result) => result.relevantResults.length)
                .map((result) => {
                  let probabilityString = "low";
                  if (result.probability > 0.5) probabilityString = "medium";
                  if (result.probability > 0.95) probabilityString = "high";

                  return (
                    <div
                      key={result.id}
                      className="key-available clickable"
                      onClick={() =>
                        handleGeneraClick(
                          result.id,
                          result.relevantResults.map((r) => r.scientific_name)
                        )
                      }
                    >
                      <KeyIcon style={{ fontSize: "2em" }} />
                      <p>
                        <Trans
                          t={t}
                          i18nKey={!clavisUuid ? "alerts.keyavailable" : "alerts.continuekey"}
                          count={result.relevantResults.length}
                        >
                          <Passthrough
                            text={
                              <span className="scientific">
                                {result.relevantResults.length &&
                                  result.relevantResults[0].name}
                              </span>
                            }
                          />
                          {{ level: t("levels." + probabilityString) }}
                          {{ taxon: result.taxon }}.
                        </Trans>
                      </p>
                    </div>
                  );
                })}

              {predictions.map((prediction) => (
                <IdResult
                  result={prediction}
                  key={prediction.scientificNameID}
                  croppedImages={croppedImages}
                  openResult={setChosenPrediction}
                />
              ))}
            </div>
          )}

          {isInputStage && (
            <UserFeedback inputStage={isInputStage} loading={isLoading} />
          )}

          <div className={"bottomButtons " + (isInputStage ? "" : "hidden")}>
            <div
              className="bottomButton newImageButton primary clickable"
              tabIndex="0"
            >
              <AddAPhotoIcon style={{ fontSize: ".8em" }} />
              <input
                className="clickable"
                type="file"
                id="uploaderImages"
                onChange={uploadMore.bind(this, "uploaderImages")}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function AppWrapper() {
  return <App />;
}
